:root {
  --black-color: #010000;
  --black-2: #333;
  --grey-color: #ddd;
  --pink-color: #f0f;
  --pink2-color: #ea00c4;
  --electric-blue-1: #0ff;
  --violet-1: #7300ff;
  --violet-2: #5a12b3;
  --white-color: #fff;
  --font-size: 2rem;
  --width-size: 80%;
  --font-family: Montserrat, sans-serif;
}

@media (width <= 83.125em) {
  :root {
    --font-size: 1.8rem;
  }

  html {
    font-size: 56.25%;
  }

  .persona-container {
    max-width: 24rem;
  }

  .personas-sub-container {
    gap: 2.4rem;
  }

  .pursuit-title, .subscribe-title {
    font-size: 4rem;
  }

  .unlock-title {
    font-size: 6rem;
  }
}

@media (width <= 68.25em) {
  html {
    font-size: 50%;
  }

  .unlock-title {
    font-size: 6rem;
  }
}

@media (width <= 60.3em) {
  html {
    font-size: 43.75%;
  }

  .underlined-black-thin:after {
    height: .2rem;
    line-height: 140%;
  }

  .underlined-pink-thick:after {
    height: 1rem;
  }

  .underlined-black-thick:after {
    height: .6rem;
  }

  .hero-container {
    z-index: 0;
  }

  .btn-menu:link, .btn-menu:visited, .btn-menu:hover, .btn-menu:active {
    font-size: 3rem;
  }

  .unlock-title {
    font-size: 5.2rem;
  }

  .unlock-text {
    font-size: 2.3rem;
  }

  .video {
    height: auto;
  }

  .personas-container {
    gap: 3.6rem;
  }

  .personas-sub-container {
    grid-row-gap: 2rem;
    grid-column-gap: 2rem;
  }

  .pursuit-title, .subscribe-title {
    font-size: 3.6rem;
  }

  .persona-title {
    font-size: 2rem;
  }

  .persona-text {
    font-size: 1.6rem;
  }

  .subscribe-title {
    font-size: 3.2rem;
  }

  .pursuit-left-col {
    width: 60%;
  }

  .pursuit-img {
    width: 40%;
  }

  .pursuit-title {
    font-size: 4rem;
  }

  .subscribe-sub-section {
    gap: 7rem;
  }

  .footer-links {
    gap: 2.6rem;
  }

  .btn-mobile-nav {
    display: block;
  }

  .nav-links {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    background-color: #fffffff7;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    transition: all .5s ease-in;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);
  }

  .nav-open .nav-links {
    opacity: .98;
    pointer-events: auto;
    visibility: visible;
    z-index: 999;
    transform: translateX(0);
  }

  .nav-links {
    flex-direction: column;
    gap: 4.8rem;
  }

  .nav-link:link, .nav-link:visited {
    font-size: 3rem;
  }

  .nav-open .close-mobile-nav {
    display: block;
  }

  .nav-open .open-mobile-nav {
    display: none;
  }

  .explore-link {
    display: inline-block;
  }

  .subscribe-button, .subscribe-button:hover, .subscribe-button:active {
    font-size: 3rem;
  }
}

@media (width <= 49.43em) {
  .persona-title {
    font-size: 1.8rem;
  }

  .persona-text {
    font-size: 1.4rem;
  }

  .persona-container {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  .unlock-title {
    font-size: 5rem;
  }

  .unlock-text {
    font-size: 2rem;
  }

  .pursuit-left-col {
    width: 70%;
  }

  .pursuit-img {
    width: 30%;
  }

  .pursuit-title {
    font-size: 4rem;
  }
}

@media (width <= 44.88em) {
  html {
    font-size: 62.5%;
  }

  .header {
    width: 100%;
    height: 10vh;
    display: block;
    position: fixed;
  }

  .container {
    width: 95%;
    margin: 0 auto;
  }

  .section, .section-small-pad {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 2.4rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 120%;
  }

  .underlined-pink-thin:after {
    height: .2rem;
  }

  .h3-span {
    font-size: 1.4rem;
  }

  .underlined-pink-thick:after {
    height: .6rem;
  }

  .underlined-black-thin:after {
    height: .2rem;
  }

  .underlined-black-thick:after {
    height: .4rem;
  }

  .underlined-pink-thin-high:after {
    height: .2rem;
  }

  .btn:link, .btn:visited, .btn:hover, .btn:active {
    border-radius: 99.9rem;
    padding: 1.2rem 2.4rem;
  }

  .overlay-mobile {
    z-index: 1000;
    background-color: var(--white-color);
    opacity: 0;
    will-change: transform, opacity;
    width: 100vw;
    height: 100vh;
    padding: 5rem 3rem;
    transition: transform .5s ease-in-out, opacity .5s ease-in-out;
    position: fixed;
    top: 0;
    right: 0;
    transform: translateY(100%);
  }

  .wellaware-logo {
    width: 6.7rem;
    height: 4.9rem;
  }

  .icon-mobile-nav {
    width: 4rem;
    height: 4rem;
  }

  .nav-container {
    z-index: 1;
    background-color: #fffffff2;
    height: 7rem;
    padding: 2rem;
  }

  .nav-link:link, .nav-link:visited, .subscribe-button, .subscribe-button:hover, .subscribe-button:active, .btn-menu:link, .btn-menu:visited, .btn-menu:hover, .btn-menu:active {
    font-size: 2rem;
  }

  .hero-container {
    z-index: 0;
    flex-direction: column;
    gap: .8rem;
    margin-top: 10vh;
    padding: 0 0 6.4rem;
    display: flex;
  }

  .hero-box {
    height: auto;
  }

  .hero-left-col {
    z-index: 0;
    order: 2;
    gap: 4rem;
    width: 100%;
    height: min-content;
  }

  .hero-btn-container {
    justify-content: center;
    width: 100%;
    display: flex;
  }

  .accelerate-title, .hero-title {
    color: var(--black-color);
    z-index: 0;
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  .hero-par {
    color: var(--black-color);
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .hero-img-container {
    order: 1;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
    display: flex;
  }

  .gif {
    object-fit: fill;
    width: 100%;
    height: 100%;
    position: static;
    transform: translate(0);
  }

  .unlock-container {
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    display: flex;
  }

  .unlock-left-col {
    order: 2;
    align-items: center;
    width: 100%;
  }

  .unlock-right-col {
    order: 1;
    width: 100%;
  }

  .unlock-title {
    text-align: center;
    font-size: 4rem;
  }

  .unlock-text {
    text-align: center;
    font-size: 1.4rem;
  }

  .journeys-container {
    gap: 2rem;
  }

  .journeys-sub-container, .journey-container {
    gap: 2.4rem;
  }

  .journey-wa-img {
    width: 18.9rem;
    height: 6.3rem;
  }

  .tldr-text {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 160%;
  }

  #tooltip-link {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 160%;
  }

  .personas-container-mobile {
    gap: 7.2rem;
  }

  .personas-sub-container {
    overflow-x: auto;
  }

  .persona-container-mobile {
    background-color: var(--white-color);
    justify-content: center;
    align-items: center;
    gap: 2.6rem;
    width: 24rem;
    height: 35rem;
    padding-left: 1rem;
    padding-right: 1rem;
    position: relative;
  }

  .persona-img-mobile {
    border-radius: 3rem;
    width: 18rem;
    height: 18rem;
    display: block;
  }

  .persona-title-mobile {
    font-size: 2.4rem;
  }

  .persona-text-mobile {
    text-align: center;
    font-size: 1.6rem;
  }

  .persona-text-cont-mobile {
    gap: 2rem;
  }

  .lore-mobile-text {
    font-size: 1.1rem;
  }

  .persona-img-mobile, .persona-text-mobile, .persona-title-mobile, .lore-mobile-text {
    transition: transform .3s, opacity .3s;
  }

  .persona-lore-text-container-mobile {
    gap: 2rem;
  }

  .lore-text-box-mobile.active {
    opacity: 1;
    transform: translateY(0);
  }

  .persona-lore-title {
    font-size: 2.4rem;
  }

  .persona-lore-text-mobile {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 160%;
  }

  #lore-link-mobile {
    color: var(--pink-color);
    font-size: 1.6rem;
    font-weight: 400;
    text-decoration: underline;
  }

  #lore-link-mobile:hover, #lore-link-mobile:focus, #lore-link-mobile:active {
    font-size: 1.6rem;
  }

  .close-overlay {
    cursor: pointer;
    color: var(--pink-color);
    background: none;
    border: none;
    font-size: 4rem;
    line-height: 1;
    position: absolute;
    top: 1rem;
    right: 2rem;
  }

  .why-grid {
    flex-direction: column;
    display: flex;
  }

  .main-article-grid {
    order: 1;
  }

  .link-order {
    order: 2;
  }

  .article-container {
    flex-direction: row;
    justify-content: space-between;
  }

  .article-text-container {
    flex-direction: column;
    order: 1;
  }

  .article-img {
    order: 2;
    width: 20%;
  }

  h5 {
    font-size: 1.6rem;
    font-weight: 700;
  }

  .main-article-flex-container {
    flex-direction: column;
    display: flex;
  }

  .main-article-container {
    flex-direction: column;
    order: 2;
    align-items: start;
  }

  .main-article-img {
    width: 100%;
    overflow: hidden;
  }

  .main-article-title {
    text-align: start;
    font-size: 2.4rem;
  }

  .main-article-text {
    text-align: start;
  }

  .btn-share {
    display: none;
  }

  .connections-container, .connections-container__recenter, .graph-hero-container {
    height: auto;
  }

  .graph-container {
    height: 40rem;
  }

  .pursuit-section {
    flex-direction: column;
    gap: 2.4rem;
  }

  .pursuit-left-col {
    text-align: center;
    order: 2;
    width: 100%;
  }

  .pursuit-title {
    font-size: 2.4rem;
  }

  .pursuit-img {
    order: 1;
    width: 60%;
  }

  .btn-carousel {
    display: none;
  }

  .carousel-container {
    gap: 1.6rem;
  }

  .testimonial-container {
    height: 12rem;
  }

  .dots {
    gap: .8rem;
  }

  .dots__dot {
    width: .8rem;
    height: .8rem;
  }

  .subscribe-sub-section {
    flex-direction: column;
    gap: 1.6rem;
  }

  .subscribe-title {
    font-size: 2.4rem;
  }

  .subscribe-text {
    text-align: center;
    width: 100%;
  }

  .hidden {
    display: none;
  }

  .footer-container {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    row-gap: 1.2rem;
  }

  .footer-social-links {
    justify-content: center;
    justify-self: center;
    display: flex;
  }

  .footer-rights {
    text-align: center;
    font-size: 1rem;
  }

  .footer-links {
    justify-content: space-around;
    justify-self: center;
  }

  .footer-link:link, .footer-link:visited, .footer-link:hover, .footer-link:active {
    font-size: 1.6rem;
  }

  .nav-container.sticky {
    z-index: 1000;
    background-color: #fffffff2;
    height: 7rem;
    padding: 2rem;
    position: fixed;
  }
}

@media (width <= 18.125em) {
  html {
    font-size: 50%;
  }
}
/*# sourceMappingURL=index.14b7ebbb.css.map */
