:root {
  --black-color: #010000;
  --black-2: #333;
  --grey-color: #ddd;
  --pink-color: #f0f;
  --pink2-color: #ea00c4;
  --electric-blue-1: #0ff;
  --violet-1: #7300ff;
  --violet-2: #5a12b3;
  --white-color: #fff;
  --font-size: 2rem;
  --width-size: 80%;
  --font-family: Montserrat, sans-serif;
}

/* Below 1333px */
@media (max-width: 83.125em) {
  :root {
    --font-size: 1.8rem;
  }

  html {
    /* 9px / 16px */
    font-size: 56.25%;
  }

  .persona-container {
    max-width: 24rem;
  }

  .personas-sub-container {
    gap: 2.4rem;
  }

  .pursuit-title,
  .subscribe-title {
    font-size: 4rem;
  }

  .unlock-title {
    font-size: 6rem;
  }
}

/* Below 1092px */
@media (max-width: 68.25em) {
  html {
    /* 8px / 16px */
    font-size: 50%;
  }

  .unlock-title {
    font-size: 6rem;
  }
}

/* Below 965px */

@media (max-width: 60.3em) {
  html {
    /* 7px / 16px */
    font-size: 43.75%;
  }

  /* RE-USABLE COMPONENTS */
  .underlined-black-thin::after {
    line-height: 140%;
    height: 0.2rem;
  }

  .underlined-pink-thick::after {
    height: 1rem;
  }

  .underlined-black-thick::after {
    height: 0.6rem;
  }

  /* HERO SECTION */

  .hero-container {
    z-index: 0;
  }

  .btn-menu:link,
  .btn-menu:visited,
  .btn-menu:hover,
  .btn-menu:active {
    font-size: 3rem;
  }

  /* UNLOCK SECTION */

  .unlock-title {
    font-size: 5.2rem;
  }

  .unlock-text {
    font-size: 2.3rem;
  }

  /* VIDEO */
  .video {
    height: auto;
  }

  /* PERSONAS SECTION */

  .personas-container {
    gap: 3.6rem;
  }

  .personas-sub-container {
    grid-row-gap: 2rem;
    grid-column-gap: 2rem;
  }

  .pursuit-title,
  .subscribe-title {
    font-size: 3.6rem;
  }

  .persona-title {
    font-size: 2rem;
  }

  .persona-text {
    font-size: 1.6rem;
  }

  /* INTELLECTUAL PURSUIT SECTION */

  .subscribe-title {
    font-size: 3.2rem;
  }

  .pursuit-left-col {
    width: 60%;
  }

  .pursuit-img {
    width: 40%;
  }

  .pursuit-title {
    font-size: 4rem;
  }

  /* SUBSCRIBE SECTION */

  .subscribe-sub-section {
    gap: 7rem;
  }

  /* FOOTER SECTION */

  .footer-links {
    gap: 2.6rem;
  }

  /* MOBILE */
  .btn-mobile-nav {
    display: block;
  }

  .nav-links {
    background-color: rgba(255, 255, 255, 0.97);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease-in;
    transform: translateX(-100%);

    /* 1) hide element visually */
    opacity: 0;
    /* 2) unaccessible to mouse and keyboard */
    pointer-events: none;
    /* 3) Hide it from screen readers */
    visibility: hidden;
  }

  .nav-open .nav-links {
    opacity: 0.98;
    pointer-events: auto;
    visibility: visible;
    transform: translateX(0);
    z-index: 999;
  }

  .nav-links {
    flex-direction: column;
    gap: 4.8rem;
  }

  .nav-link:link,
  .nav-link:visited {
    font-size: 3rem;
  }

  .nav-open .close-mobile-nav {
    display: block;
  }

  .nav-open .open-mobile-nav {
    display: none;
  }

  .explore-link {
    display: inline-block;
  }

  .subscribe-button,
  .subscribe-button:hover,
  .subscribe-button:active {
    font-size: 3rem;
  }
}

/* Below 791px */

@media (max-width: 49.43em) {
  .persona-title {
    font-size: 1.8rem;
  }
  .persona-text {
    font-size: 1.4rem;
  }

  .persona-container {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  /* UNLOCK SECTION */

  .unlock-title {
    font-size: 5rem;
  }

  .unlock-text {
    font-size: 2rem;
  }

  /* INTELLECTUAL PURSUIT SECTION */

  .pursuit-left-col {
    width: 70%;
  }

  .pursuit-img {
    width: 30%;
  }

  .pursuit-title {
    font-size: 4rem;
  }
}

/* Below 718px */
@media (max-width: 44.88em) {
  /* GENERAL COMPONENTS */

  html {
    /* 10px / 16px */
    font-size: 62.5%;
  }

  .header {
    height: auto;
    display: block;

    position: fixed;
    height: 10vh;
    width: 100%;
  }

  /* RE-USABLE COMPONENTS */

  .container {
    margin: 0 auto;
    width: 95%;
  }

  .section {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }

  .section-small-pad {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem;
  }

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 1.4rem;
  }

  h4 {
    font-size: 2.4rem;
  }

  p {
    font-size: 1.4rem;
    line-height: 120%;
  }

  .underlined-pink-thin::after {
    height: 0.2rem;
  }

  .h3-span {
    font-size: 1.4rem;
  }

  .underlined-pink-thick::after {
    height: 0.6rem;
  }

  .underlined-black-thin::after {
    height: 0.2rem;
  }

  .underlined-black-thick::after {
    height: 0.4rem;
  }

  .underlined-pink-thin-high::after {
    height: 0.2rem;
  }

  .btn:link,
  .btn:visited,
  .btn:hover,
  .btn:active {
    border-radius: 99.9rem;
    padding: 1.2rem 2.4rem;
  }

  .overlay-mobile {
    position: fixed; /* Overlay above everything */
    top: 0; /* Align with top of viewport */
    right: 0; /* Align with right of viewport */
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    padding: 5rem 3rem; /*Consistent padding */

    z-index: 1000; /* Ensure it appears above other elements */
    background-color: var(--white-color);
    transform: translateY(100%); /* Start off-screen at the bottom */
    opacity: 0;
    transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
    will-change: transform, opacity;
  }

  /* NAVIGATION */

  .wellaware-logo {
    width: 6.7rem;
    height: 4.9rem;
  }

  .icon-mobile-nav {
    width: 4rem;
    height: 4rem;
  }

  .nav-container {
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1;
    padding: 2rem;

    height: 7rem;
  }

  .nav-link:link,
  .nav-link:visited {
    font-size: 2rem;
  }

  .subscribe-button,
  .subscribe-button:hover,
  .subscribe-button:active {
    font-size: 2rem;
  }

  .btn-menu:link,
  .btn-menu:visited,
  .btn-menu:hover,
  .btn-menu:active {
    font-size: 2rem;
  }

  /* HERO SECTION */

  .hero-container {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    padding: 0 0 6.4rem 0;
    margin-top: 10vh;
    z-index: 0;
  }

  .hero-box {
    height: auto;
  }

  .hero-left-col {
    width: 100%;
    height: min-content;
    order: 2;

    gap: 4rem;
    z-index: 0;
  }

  .hero-btn-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .accelerate-title,
  .hero-title {
    color: var(--black-color);

    /* Heading1/Small */
    font-size: 4rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    z-index: 0;
  }

  .hero-par {
    color: var(--black-color);
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .hero-img-container {
    order: 1;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gif {
    position: static;
    width: 100%;
    height: 100%;

    object-fit: fill;
    transform: translate(0);
  }

  /* UNLOCK SECTION */

  .unlock-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 2rem;

    text-align: center;
  }

  .unlock-left-col {
    width: 100%;
    order: 2;

    align-items: center;
  }

  .unlock-right-col {
    width: 100%;
    order: 1;
  }

  .unlock-title {
    text-align: center;
    font-size: 4rem;
  }

  .unlock-text {
    font-size: 1.4rem;
    text-align: center;
  }

  /* WHY WELLAWARE.CARE */

  .journeys-container {
    gap: 2rem;
  }

  .journeys-sub-container {
    gap: 2.4rem;
  }

  .journey-container {
    gap: 2.4rem;
  }

  .journey-wa-img {
    width: 18.9rem;
    height: 6.3rem;
  }

  /* CONNECTIONS */

  h5 {
    font-size: 2rem;
    font-weight: 600;
  }

  .tldr-text {
    font-size: 1.6rem;
    line-height: 160%;
    font-weight: 400;
  }

  #tooltip-link {
    font-size: 1.6rem;
    line-height: 160%;
    font-weight: 600;
  }

  /* PERSONAS SECTION */

  .personas-container-mobile {
    gap: 7.2rem;
  }

  .personas-sub-container {
    overflow-x: auto;
  }

  .persona-container-mobile {
    width: 24rem;
    height: 35rem;
    padding-left: 1rem;
    padding-right: 1rem;

    align-items: center;
    justify-content: center;
    gap: 2.6rem;

    background-color: var(--white-color);

    position: relative;
  }

  .persona-img-mobile {
    width: 18rem;
    height: 18rem;

    display: block;

    border-radius: 3rem;
  }

  .persona-title-mobile {
    font-size: 2.4rem;
  }

  .persona-text-mobile {
    font-size: 1.6rem;
    text-align: center;
  }

  .persona-text-cont-mobile {
    gap: 2rem;
  }

  .lore-mobile-text {
    font-size: 1.1rem;
  }

  /* Base transitions for all elements */
  /* TODO: understand if this works or has to be deleted */
  .persona-img-mobile,
  .persona-text-mobile,
  .persona-title-mobile,
  .lore-mobile-text {
    transition: transform 300ms ease, opacity 300ms ease;
  }

  .persona-lore-text-container-mobile {
    gap: 2rem;
  }

  /* New description: active state */
  .lore-text-box-mobile.active {
    transform: translateY(0);
    opacity: 1;
  }

  .persona-lore-title {
    font-size: 2.4rem;
  }

  .persona-lore-text-mobile {
    font-size: 1.6rem;
    line-height: 160%;
    font-weight: 400;
  }

  #lore-link-mobile {
    font-size: 1.6rem;
    font-weight: 400;
    color: var(--pink-color);
    text-decoration: underline;
  }

  #lore-link-mobile:hover,
  #lore-link-mobile:focus,
  #lore-link-mobile:active {
    font-size: 1.6rem;
  }

  .close-overlay {
    position: absolute;
    top: 1rem;
    right: 2rem;
    background: none;
    border: none;
    font-size: 4rem;
    cursor: pointer;
    line-height: 1;
    color: var(--pink-color); /* Adjust color as needed */
  }

  /* MAGAZINE VIEW */

  .why-grid {
    display: flex;

    flex-direction: column;
  }

  /* Order of the articles */
  .main-article-grid {
    order: 1;
  }
  .link-order {
    order: 2;
  }

  .article-container {
    flex-direction: row;

    justify-content: space-between;
  }

  .article-text-container {
    flex-direction: column;
    order: 1;
  }

  .article-img {
    order: 2;
    width: 20%;
  }

  h5 {
    font-size: 1.6rem;
    font-weight: 700;
  }

  /* Main article */

  .main-article-flex-container {
    display: flex;

    flex-direction: column;
  }

  .main-article-container {
    flex-direction: column;
    align-items: start;

    order: 2;
  }

  .main-article-img {
    width: 100%;

    overflow: hidden;
  }

  .main-article-title {
    font-size: 2.4rem;
    text-align: start;
  }

  .main-article-text {
    text-align: start;
  }

  /* CONNECTIONS */
  .btn-share {
    display: none;
  }

  .connections-container {
    height: auto;
  }

  .connections-container__recenter {
    height: auto;
  }

  .graph-hero-container {
    height: auto;
  }

  .graph-container {
    height: 40rem;
  }

  /* INTELLECTUAL PURSUIT SECTION */

  .pursuit-section {
    flex-direction: column;
    gap: 2.4rem;
  }

  .pursuit-left-col {
    order: 2;
    width: 100%;
    text-align: center;
  }

  .pursuit-title {
    font-size: 2.4rem;
  }

  .pursuit-img {
    width: 60%;

    order: 1;
  }

  /* WHAT OUR READERS SAY */
  .btn-carousel {
    display: none;
  }

  .carousel-container {
    gap: 1.6rem;
  }

  .testimonial-container {
    height: 12rem;
  }

  .dots {
    gap: 0.8rem;
  }

  .dots__dot {
    width: 0.8rem;
    height: 0.8rem;
  }

  /* STAY UPDATED SECTION */

  .subscribe-sub-section {
    flex-direction: column;
    gap: 1.6rem;
  }

  .subscribe-title {
    font-size: 2.4rem;
  }

  .subscribe-text {
    width: 100%;
    text-align: center;
  }

  .hidden {
    display: none;
  }

  /* FOOTER */
  .footer-container {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    justify-content: center;
    row-gap: 1.2rem;
  }

  .footer-social-links {
    display: flex;
    justify-content: center;

    /* Grid- justify element*/
    justify-self: center;
  }

  .footer-rights {
    text-align: center;
    font-size: 1rem;
  }

  .footer-links {
    justify-content: space-around;

    /* Grid- justify element*/
    justify-self: center;
  }

  .footer-link:link,
  .footer-link:visited {
    font-size: 1.6rem;
  }

  .footer-link:hover,
  .footer-link:active {
    font-size: 1.6rem;
  }

  /* STICKY NAVIGATION */

  .nav-container.sticky {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1000;
    padding: 2rem;

    height: 7rem;
  }
}

@media (max-width: 18.125em) {
  html {
    /* 9px / 16px */
    font-size: 50%;
  }
}
